<template>
  <div class="refund">
    <div class="df-row">
      <!-- 日期时间选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 搜索框 -->
        <el-input placeholder="请输入" v-model="submitData.word" size="mini">
        </el-input>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="getRefundMaintList"
          >查询</el-button
        >
      </div>

      <div class="df-col">
          <el-button type="primary" size="mini" @click="onEditDate"
            >修改日期</el-button
          >
        </div>
    </div>

    <div>
      <!-- start 表格 -->
      <el-table
        :data="refundList"
        :max-height="tableMaxHeight"
        border
        stripe
        v-loading="loading"
        @selection-change="onChangeSelection"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column prop="BillNo" label="单号" width="110">
        </el-table-column>
        <el-table-column prop="" label="会员信息" width="120">
          <template slot-scope="scope">
            <div class="member-info">
              <span>{{ scope.row.MemberName }}</span>
              <span>{{ scope.row.MemberPhone }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="MemberPhone" label="会员手机"> </el-table-column> -->
        <el-table-column prop="MemberStoresName" label="所属门店" width="210">
        </el-table-column>
        <el-table-column prop="" label="会员卡信息" width="120">
          <template slot-scope="scope">
            <div class="vip-info">
              <span>{{ scope.row.VipNo }}</span>
              <span>{{
                `${scope.row.CardName}(${scope.row.CardNatureName})`
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="CardName" label="会员卡类型"> </el-table-column> -->
        <!-- <el-table-column prop="CardNatureName" label="会员卡属性">
        </el-table-column> -->
        <el-table-column prop="KeepMoney" label="退款(充值金额)" width="120">
        </el-table-column>
        <el-table-column prop="DonaMoney" label="退款(赠送金额)" width="120">
        </el-table-column>
        <el-table-column prop="Inte" label="退款(积分)" width="90">
        </el-table-column>
        <el-table-column label="记账日期" width="100">
          <template slot-scope="scope">
            {{
              util.dateFormat(
                "YYYY-mm-dd",
                new Date(scope.row.SoftwareDate * 1000)
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="退款时间" width="100">
          <template slot-scope="scope">
            <div class="refund-time">
              {{
                util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.RefundTime * 1000)
                )
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="RefundAdminName" label="操作者" width="90">
        </el-table-column>
        <el-table-column prop="RefundNote" show-overflow-tooltip label="备注">
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              :disable="cancelDisabled"
              @click="reverseRefund(scope.row)"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- end 表格 -->

      <!-- start 分页器 -->
      <el-pagination
        background
        :page-sizes="[10, 15, 20, 25]"
        :page-size="submitData.page_size"
        :current-page="submitData.page_index"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--end 分页器-->
    </div>

    <el-dialog
      title="修改记账日期"
      append-to-body
      width="400px"
      :visible.sync="editVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseEdit"
    >
      <el-form label-width="80px">
        <el-form-item label="选择日期">
          <el-date-picker
            class="date-picker"
            v-model="softwareDate"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseEdit">取 消</el-button>
        <el-button type="primary" :disabled="editDisabled" @click="alterRefundSoftwareDate"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vip from "@/api/vip";

export default {
  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      softwareDate: '',
      totalRecord: 0,
      submitData: {
        // 搜索关键字
        word: "",
        // 开始日期
        begin_date: "",
        // 结束日期
        end_date: "",
        page_index: 1,
        page_size: 10,
      },
      multipleDate: [],
      refundList: [],
      selectItems: [],
      loading: false, // 表格加载
      cancelDisabled: false, // 取消退款防抖
      editVisible: false,
      editDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];

    this.onChangeDate();
    this.getRefundMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 退款查询
    async getRefundMaintList() {
      this.loading = true;
      try {
        let { data } = await Vip.getRefundMaintList(this.submitData);
        this.refundList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 修改记账日期
    async alterRefundSoftwareDate(){
      let { selectItems, softwareDate } = this;

      if (!softwareDate) {
        return this.$message.info("请选择要还款日期");
      }

      this.editDisabled = true;

      try {
        let arr = [];
        selectItems.forEach((item) => arr.push(item.BillGuid));

        let submitData = {
          selectors: arr,
          softwareDate,
        };
        // console.log(submitData);
        let { data, errcode } = await Vip.alterRefundSoftwareDate(submitData);

        if (errcode == 0) {
          this.$message.success("日期修改成功");
          this.getRefundMaintList();
          this.onCloseEdit();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.editDisabled = false;
      }
    },

    // 取消退款
    reverseRefund(event) {
      let that = this;
      this.$confirm("是否取消退款？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.cancelDisabled = true;
        try {
          let { errcode } = await Vip.reverseRefund({
            bill_guid: event.BillGuid,
          });
          if (errcode == 0) {
            that.$message.success("取消退款成功");
            that.getRefundMaintList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          that.cancelDisabled = false;
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 32);
    },

    // 多选框被改变事件
    onChangeSelection(event) {
      console.log(event);
      this.selectItems = event;
    },

    // 日期时间改变事件
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
      this.submitData.page_index = 1;
    },

    // 打开修改日期对话框
    onEditDate() {
      let { selectItems } = this;
      if (!selectItems.length) {
        return this.$message.info("请选择要修改记账日期的退款记录");
      }
      this.onShowEdit();
    },

    // 打开修改日期对话框
    onShowEdit() {
      this.editVisible = true;
    },

    // 关闭修改日期对话框
    onCloseEdit() {
      this.editVisible = false;
      this.softwareDate = "";
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.refund {
  .el-table {
    .member-info,
    .vip-info {
      .flex-col;
      align-items: unset;
    }
  }
  .el-pagination {
    margin-top: 10px;
    float: right;
  }
}
</style>
